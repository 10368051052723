.sme-banner {
  background-image: url("../../../public/images/sme/BackgroundBanner.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 768px;
}

.sme-support {
  .common-section-partner {
    background-image: url("../../../public/images/support/Backgound.svg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    &__list {
      &::before {
        background: linear-gradient(90deg, #f9fafb 0%, rgba(249, 250, 251, 0) 100%);
      }
      &::after {
        background: linear-gradient(270deg, #f9fafb 0%, rgba(250, 250, 250, 0) 100%);
      }
      .carousel__slide {
        height: 144px;
      }
    }
  }
}

.sme-posts {
  .ant-segmented {
    .ant-segmented-item {
      text-align: left;
    }
    .ant-segmented-item-label {
      overflow: auto;
      white-space: normal;
      line-height: 24px;
      min-height: 24px;
      padding: 8px 12px;
      color: #667085;
    }
  }
  .container-fuild {
    background-image: url("../../../public/images/sme/BackgroundResourceBanner.svg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .ant-pagination {
    display: flex;
    justify-content: center;
    .ant-pagination-prev {
      margin-right: auto;
      @apply h-10 lg:h-7;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-pagination-next {
      margin-left: auto;
      @apply h-10 lg:h-7;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-pagination-item {
      @apply min-w-[40px] h-10 lg:min-w-[28px] lg:h-7;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
