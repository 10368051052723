.ant-select {
  &.ant-select-single {
    @apply h-11;
  }
  .ant-select-selector {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-color: #d0d5dd !important;
    @apply rounded-lg min-h-[44px] text-md;
  }
  .ant-select-selection-placeholder {
    color: #667085;
  }
}

[class^="ant-select"],
[class*=" ant-select"] {
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}
.ant-select .ant-select-arrow > * {
  font-size: 13px;
}

// .ant-select.ant-select-single:not(.ant-select-customize-input) {
//   @apply h-auto;
// }

// .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
//   @apply h-auto px-6;
// }

// .ant-select-show-search .ant-select-selector {
//   @apply px-6;
// }

// .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
//   height: 100% !important;
// }

// .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
//   @apply h-[48px];
// }

// .ant-select .ant-select-selection-placeholder {
//   @apply text-body3 text-[#858C94];
// }

// .ant-select-selection-item {
//   @apply text-body3;
// }

// .ant-select-single .ant-select-selector .ant-select-selection-search {
//   inset-inline-start: 24px;
// }

// // Select multiple search

// .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
//   @apply h-11;
//   &::placeholder {
//     color: #667085;
//   }
// }
// .ant-select-multiple .ant-select-selection-placeholder {
//   inset-inline-start: 24px;
//   inset-inline-end: 24px;
// }
// .ant-select-multiple .ant-select-selection-search {
//   margin-inline-start: 0px;
// }

.ant-select-item-option-content,
.ant-select-selection-placeholder,
.ant-select-selection-item,
.ant-select-item-option-state {
  font-family: Inter, sans-serif !important;
}
