.vulnerability-banner {
  background-image: url("../../../public/images/vulnscan/vulnerability-database/bg-banner.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.vulnscan-subheader {
  transition: all 0.3s ease;
  background-color: #fff;
  z-index: 10;
  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}
.section-vulnscan,
.vulnscan-security-reports,
.layout-blog {
  thead {
    .ant-table-cell {
      font-size: 16px;
      line-height: 24px;
    }
  }
  tbody {
    .ant-table-cell {
      font-size: 14px;
    }
  }
  .ant-pagination {
    display: flex;
    justify-content: center;
    .ant-pagination-prev {
      margin-right: auto;
      @apply h-10 lg:h-7;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-pagination-next {
      margin-left: auto;
      @apply h-10 lg:h-7;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-pagination-item {
      @apply min-w-[40px] h-10 lg:min-w-[28px] lg:h-7;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.vulnscan-vulnerability-detail {
  .new-container {
    h2,
    h3 {
      font-family: "Inter" !important;
    }
  }
  .vulnerability-banner-detail {
    background-image: url("../../../public/images/vulnscan/vulnerability-database/bg-banner-detail.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.vulnscan-features-heading {
  .ant-segmented {
    max-width: 100%;
    padding: 6px;
    background-color: #f9fafb;
    border-radius: 8px;
    border: 1px solid #eaecf0;
    @apply sm:overflow-x-auto;
    .ant-segmented-group {
      max-width: 100%;
    }
    .ant-segmented-item-label {
      padding: 8px 20px;
      @apply text-[16px] md:text-[14px] py-2 px-5 md:py-1 md:px-3;
      line-height: 28px;
      color: #667085;
      font-weight: 600;
    }
    .ant-segmented-item-selected {
      box-shadow:
        0px 1px 3px 0px rgba(16, 24, 40, 0.1),
        0px 1px 2px 0px rgba(16, 24, 40, 0.06) !important;
      .ant-segmented-item-label {
        color: #344054;
      }
    }
  }
}

.vulnscan-report-banner {
  background-image: url("../../../public/images/vulnscan/report/bg-banner.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.vulnscan-report {
  .ant-card {
    .ant-card-head {
      min-height: 78px;
      background: #f9fafb;
      padding: auto 32px;
      .ant-card-head-title {
        font-size: 20px;
        line-height: 30px;
        color: #101828;
      }
    }
    &.ant-card-bordered {
      border-color: #e4e7ec;
      overflow: hidden;
    }
  }
  &.vulnscan-report-overview {
    .ant-card-body {
      padding: 32px;
    }
  }
  &.vulnscan-report-list {
    .ant-card {
      border-radius: 16px;
      .ant-card-head {
        border-radius: 16px 16px 0 0;
        padding-top: 24px;
        padding-bottom: 24px;
      }
    }
    .ant-card-body {
      padding: 0;
    }
  }
}

.vulnscan-security-reports {
  .ant-checkbox-group {
    .ant-checkbox-wrapper {
      font-size: 16px;
      font-weight: 500;
      color: #344054;
      line-height: 24px;
    }
  }
}
.vulnscan-security-reports-banner {
  .new-container {
    background-image: url("../../../public/images/vulnscan/bg-banner-security-reports.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.vulnscan-pricing-tooltip-overlay {
  max-width: 420px;
  .ant-tooltip-inner {
    padding: 12px;
  }
}

.vulnscan-pricing {
  &.detail-features {
    .ant-table {
      border: 1px solid #d0d5dd;
      overflow: hidden;
      border-radius: 8px;
      tr {
        td {
          border-bottom: 1px solid #d0d5dd;
          width: calc(50% / 3);
          font-size: 14px;
          line-height: 24px;
          padding: 16px 24px;
          &:first-child {
            width: 50%;
          }
        }
        &:nth-child(2n) {
          td {
            background-color: #f9fafb;
          }
        }
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
}
