.product-frequently-question {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    @apply py-[22px];
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
    @apply text-black text-h6 font-bold;
  }

  .ant-collapse-ghost > .ant-collapse-item {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      right: 15%;
      height: 2px;
      background-color: #ebeef2;
    }
    @apply mb-6;
  }
}

.project-detail {
  .ant-collapse {
    &.custom-header {
      .ant-collapse-header {
        align-items: center !important;
        @apply py-[30px] px-0;
      }
    }
    &.sub-collapse {
      .ant-collapse-header {
        align-items: center !important;
        @apply py-0 px-0;
      }
    }
  }

  .ant-collapse-ghost > .ant-collapse-item {
    position: relative;
    @apply mb-0;
  }

  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 30px;
    padding: 8px 0 30px 0 !important;
  }
}

.trust-center-faq {
  .ant-collapse {
    .ant-collapse-item {
      border-radius: 16px;
      @apply p-8 lg:p-6 md:p-4;
      &.ant-collapse-item-active {
        background-color: #f9fafb;
      }
      .ant-collapse-header {
        padding: 0;
      }
    }
  }
}

.trust-center-common-challenge {
  .ant-collapse {
    @apply space-y-6;
    .ant-collapse-item {
      border-radius: 16px;
      @apply p-8 lg:p-6 md:p-4;
      border: 1px solid transparent;
      &.ant-collapse-item-active {
        background-color: #fff;
        border: 1px solid #e4e7ec;
      }
      .ant-collapse-header {
        padding: 0;
      }
      .ant-collapse-content-box {
        padding-bottom: 0;
      }
    }
  }
}
