.ant-checkbox {
  transform: translate(0, 0)
}
.ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type="checkbox"] {
  width: 20px;
  height: 20px;
}
.ant-checkbox .ant-checkbox-inner:after {
  inset-inline-start: 23%
}