.tcw {
  .ant-collapse {
    &.faq {
      .ant-collapse-item {
        @apply bg-white border-gray-300;
        &-active {
          @apply bg-gray-100 border-b-0;
        }
        .ant-collapse-header {
          display: flex;
          align-items: center;
          @apply px-4 lg:px-6 pt-4 lg:pt-6;
          .ant-collapse-expand-icon {
            padding-inline-end: 0 !important
          }
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            @apply pl-[50px] lg:pl-[58px] pb-4 pr-4 lg:pb-6 lg:pr-6;
          }
        }
      }
    }
  }
}