.platform {
  &.platform-banner {
    .new-container {
      background-image: url("../../../public/images/platform/bg-banner.svg");
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &.platform-solution {
    .new-container {
      border-top: 1px solid #eaecf0;
    }
  }
}
