.form-ransomware {
  .ant-modal-content {
    padding-top: 44px;
  }
}
.page-ransomware {
  * {
    @apply tracking-tighter;
  }
}

.ransomware-partner {
  .common-section-partner {
    &__list {
      &::before {
        background: linear-gradient(90deg, #f0f0f0 0%, rgba(240, 240, 240, 0) 100%);
      }
      &::after {
        background: linear-gradient(270deg, #f0f0f0 0%, rgba(240, 240, 240, 0) 100%);
      }
    }
  }
}
