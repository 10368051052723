.home-banner {
  position: relative;
  background-image: url("../../../public/images/home/banner/bg-banner.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  @apply bg-cover lg:bg-contain;
}

.home-contact {
  .home-contact-form {
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: #2222ffb2;
      filter: blur(80px);
    }

    // .ant-input {
    //   &::placeholder {
    //     &::first-letter {
    //       color: red;
    //     }
    //   }
    // }
  }
}

.homepage-features {
  .nav-item {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 100%;
      bottom: -1px;
      height: 3px;
      background-color: #22f;
      transition: all 0.2s ease;
    }
    &.is-active {
      &::before {
        right: 0;
      }
    }
  }
  .image-blur {
    position: relative;
    img {
      filter: grayscale(100%) invert(0.5);
      transition: all 0.2s ease;
      &:hover {
        filter: none;
      }
    }
  }
  .box-container {
    box-shadow:
      0px 87px 52px 0px rgba(143, 143, 143, 0.03),
      0px 39px 39px 0px rgba(143, 143, 143, 0.05),
      0px 10px 21px 0px rgba(143, 143, 143, 0.06);
  }
}

.home-partner {
  .common-section-partner {
    &__list {
      &::before {
        background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
      }
      &::after {
        background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }
}

.home-testimonial {
  .swiper-wrapper {
    @apply translate-x-[calc(50%-450px)] xl:translate-x-[calc(50%-400px)] lg:translate-x-[calc(50%-300px)] md:translate-x-[calc(50%-240px)] sm:translate-x-[calc(50%-160px)];
  }
  .swiper-slide {
    @apply w-[905px] h-[420px] xl:w-[800px] xl:h-[380px] lg:w-[600px] lg:h-[320px] md:max-w-[480px] md:max-h-[280px] sm:max-w-[320px] sm:max-h-[220px];
  }
}

.home-platform {
  .new-container {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-image: url("../../../public/images/home/platform/bg-section.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 0;
    }
    .filter-blur {
      position: absolute;
      top: 28%;
      left: 7%;
      right: 7%;
      bottom: 40%;
      @apply xl:top-[20%];
      background: #0c0b11;
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        background: rgba(34, 34, 255, 0.5);
        filter: blur(117.55524444580078px);
      }
    }
  }
  .box-container {
    clip-path: polygon(10% 0, 90% 0, 100% 20%, 100% 80%, 90% 99%, 10% 99%, 0% 80%, 0% 20%);
    width: 140px;
    height: 70px;
    @apply xl:w-[120px] xl:h-[60px] lg:w-[100px] lg:h-[50px] md:w-[70px] md:h-[35px] sm:w-[40px] sm:h-[24px];
    background: rgb(34, 34, 255);
    background: linear-gradient(
      135deg,
      rgba(34, 34, 255, 1) 0%,
      rgba(196, 196, 255, 1) 33%,
      rgba(55, 54, 68, 1) 66%,
      rgba(34, 34, 255, 1) 100%
    );
    &__inner {
      clip-path: polygon(10% 0, 90% 0, 100% 20%, 100% 80%, 90% 99%, 10% 99%, 0% 80%, 0% 20%);
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background-color: #0c0b11;
    }
  }
  .box-logo {
    clip-path: polygon(2% 0, 98% 0, 100% 20%, 100% 80%, 98% 100%, 2% 100%, 0% 80%, 0% 20%);
    height: 155px;
    @apply lg:h-[120px] md:h-[100px] sm:h-[70px];
    background: linear-gradient(
      135deg,
      rgba(34, 34, 255, 1) 0%,
      rgba(196, 196, 255, 1) 33%,
      rgba(55, 54, 68, 1) 66%,
      rgba(34, 34, 255, 1) 100%
    );
    &__inner {
      clip-path: polygon(2% 0, 98% 0, 100% 20%, 100% 80%, 98% 100%, 2% 100%, 0% 80%, 0% 20%);
      width: calc(100% - 1px);
      height: calc(100% - 1px);
      position: relative;
      background-image: url("../../../public/images/home/platform/bg-logo.svg");
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        background-image: url("../../../public/images/home/platform/bg-logo-inner.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
