.ant-dropdown {
  &.tcw {
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        .ant-dropdown-menu-title-content {
          p {
            @apply text-blue-800
          }
        }
        &:hover {
          @apply bg-blue-100;
        }
        &.ant-dropdown-menu-item-active,
        &.ant-dropdown-menu-item-selected {
          .ant-dropdown-menu-title-content {
            p {
              @apply text-primary;
            }
          }
        }
      }
    }
    &.nav-dropdown {
      position: fixed;
      top: 66px;
    }
  }
}