.tcw {
  .ant-btn {
    @apply text-md font-medium;
    .ant-btn-icon {
      @apply text-xl font-medium;
    }
    &.ant-btn-default {
      @apply bg-blue-100 border-blue-100;
    }
    &.ant-btn-background-ghost {
      @apply bg-blue-50;
    }
  }
}
