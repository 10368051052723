.blog-banner {
  .container-fuild {
    background-image: url("../../../public/images/blog/bg-banner.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.modal-subscribe {
  * {
    font-family: "Inter";
  }
  .ant-modal-content {
    padding: 24px;
    background-image: url("../../../public/images/blog/Mask.svg");
    background-position: top left !important;
    background-repeat: no-repeat;
    background-size: 230px;
  }
  .ant-modal-body {
    position: relative;
  }
  label {
    font-weight: 500;
    line-height: 20px !important;
  }
  .ant-modal-close {
    width: 44px;
    height: 44px;
    svg {
      font-size: 20px;
    }
  }
}
