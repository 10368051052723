@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-h1 font-bold mb-0
  }
  h2 {
    @apply text-h2 font-bold mb-0
  }
  h3 {
    @apply text-h3 font-bold mb-0
  }
  h4 {
    @apply text-h4 font-bold mb-0
  }
  h5 {
    @apply text-h5 font-bold mb-0
  }
  h6 {
    @apply text-h6 font-bold mb-0
  }
  p {
    @apply mb-0
  }
  small {
    @apply text-small mb-0
  }
}

.text-body1sb {
  font-weight: 600;
}
.text-body2sb {
  font-weight: 600;
}
.text-body3sb {
  font-weight: 600;
}
.text-body4sb {
  font-weight: 600;
}
.text-body5sb {
  font-weight: 600;
}