.tcw {
  #navbar {
    @apply bg-blue-800;
    background-image: url(../../assets/images/nav/background.png);
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: 100%
  }
  #footer {
    @apply bg-blue-800;
    background-image: url(../../assets/images/footer/background.png);
    background-repeat: no-repeat;
    background-position-y: 100%
  }
  * {
    font-family:
      "Inter",
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      "Open Sans",
      "Helvetica Neue",
      sans-serif;
  }
  
  h1,
  h2,
  h3 {
    @apply text-black;
  }
  
  p,
  li {
    @apply text-md text-gray mb-0;
  }
  
  b,
  strong {
    @apply text-black;
  }
  
  .section-container {
    @apply max-w-7xl lg:px-4 px-6 mx-auto;
    &.main-content {
      min-height: calc(100vh - 64px - 194px - 214px) !important;
    }
  }
  
  #navbar,
  #subnavbar {
    @apply top-0 transition-transform;
    .logo-white {
      @apply opacity-0;
    }
    .white-btn {
      @apply text-black;
    }
  
    .nav-item {
      &.active {
        .nav-item-label {
          @apply text-white;
        }
      }
      .nav-item-label {
        @apply text-sm text-gray-500 font-medium mb-0 py-5 ml-8 cursor-default transition-colors;
      }
  
      .nav-item-label:hover {
        @apply text-white;
      }
    }
  }
  
  #navbar {
    @apply z-50;
  }
  
  #subnavbar {
    @apply z-40 shadow-md bg-white;
  }
  
  #navbar.scrolled {
    @apply shadow-md;
  }
  
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  strong {
    @apply font-semibold;
  }
  
  .text-limited {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
  }
  
  .left-menu-item {
    position: relative;
    p {
      @apply text-gray-500;
    }
    &:hover,
    &.active {
      @apply bg-blue-100 font-medium;
      p {
        @apply text-primary;
      }
      &::before {
        content: '';
        left: 0;
        position: absolute;
        @apply h-2/3 w-1 rounded-r-md bg-primary;
      }
    }
  }
}