// sass-utils
@import "./sass-utils/_fonts";

// vendors
@import "./vendors/_tailwind";
@import "./vendors/_antd";

@import "./pages/index.scss";

// base
.ant-carousel .slick-dots {
  z-index: 5;
}