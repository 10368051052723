.data-secrity-banner {
  background-image: url("../../../public/images/data-security/bg-banner.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.data-secrity-comment {
  .user-info {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(12px);
  }
}
