.mark-down {
  h2 {
    &:first-child {
      margin-top: 0;
    }
    margin-top: 20px;
  }
  ul {
    margin-left: 20px;
  }

  pre {
    background-color: rgba(149, 149, 165, 0.19);
    padding: 4px 8px;
  }
}