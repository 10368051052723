.infrastructure-banner {
  background-image: url("../../../public/images/infrastructure/BackgroundBanner.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-compliance {
  background-image: url("../../../public/images/BackgroundCompliance.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.infrastructure-methodology {
  background-image: url("../../../public/images/infrastructure/BackgroundOurMethodology.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.infrastructure-subheader {
  transition: all 0.3s ease;
  background-color: #fff;
  z-index: 10;
  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}
