.endpoint-banner {
  background-image: url("../../../public/images/endpoint/bg-banner.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.endpoint-info {
  .endpoint-container-info {
    background-image: url("../../../public/images/endpoint/bg-info.svg");
    background-position: center -322px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.endpoint-solutions {
  .card-solution {
    box-shadow:
      0px 12px 30px -4px rgba(16, 24, 40, 0.08),
      0px 4px 12px -2px rgba(16, 24, 40, 0.03);
  }
}
