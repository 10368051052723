.red-teaming-banner {
  background-image: url("../../../public/images/red-teaming/BackgroundBanner.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.why-choose-red-teaming {
  .ant-collapse-item {
    border-radius: 16px !important;
    @apply border border-solid border-dark-border-section;
    border-bottom-width: 1px !important;
    border-style: solid !important;
    border-color: #eaecf0 !important;
    .ant-collapse-header {
      align-items: center !important;
      border-radius: 16px !important;
      @apply p-4 bg-[#F2F4F7];
      .ant-collapse-arrow {
        font-size: 16px !important;
      }
    }
    .ant-collapse-content-box {
      padding-top: 0 !important;
    }
    &.ant-collapse-item-active {
      .ant-collapse-header {
        background-color: #fff;
        border-radius: 16px 16px 0 0 !important;
      }
      .ant-collapse-content-active {
        background-color: #fff;
        border-radius: 0 0 16px 16px !important;
      }
    }
  }
}

.our-process-red-teaming {
  .ant-steps.ant-steps-vertical {
    gap: 24px;
  }
  .ant-steps.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    height: 140%;
    &::after {
      width: 2px;
    }
  }
  .ant-steps-item-title {
    width: 100%;
  }
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 0;
      padding-top: 4px !important;
      align-items: center !important;
      .ant-collapse-arrow {
        font-size: 14px !important;
      }
    }
    .ant-collapse-content-box {
      padding-left: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
