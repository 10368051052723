.ant-input {
  @apply rounded-lg border-[#D0D5DD] text-md h-11 px-[14px] text-dark-new-title font-normal;
  &::placeholder {
    color: #667085;
  }
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  &.ant-input-lg {
    @apply py-3;
  }
}

.ant-picker-input {
  input {
    @apply placeholder:text-[#858C94] text-md;
  }
}

.ant-input-affix-wrapper {
  @apply py-0;
}

input {
  font-family: Inter, sans-serif !important;
}
