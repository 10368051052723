.data-leak-banner {
  background-image: url("../../../public/images/data-leak/BackgroundBanner.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .ant-input-group-addon {
    background-color: #f9fafb;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.data-leak-data-breaches {
  .logo-center {
    @apply w-[244px] xl:w-[200px] lg:w-[160px] md:w-[80px] h-[244px] xl:h-[200px] lg:h-[160px] md:h-[80px];
    background-image: url("../../../public/images/data-leak/BackgroundLogo.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.data-leak-comprehensive-monitoring {
  .ant-segmented.ant-segmented-lg .ant-segmented-item-label {
    padding: 10px 20px;
    line-height: 24px;
  }
  .ant-steps-item-title {
    @apply text-lg font-semibold text-dark-700;
  }
  .ant-steps-item-description {
    @apply text-lg text-dark-new-title;
  }
  .ant-steps-item-tail {
    &::after {
      width: 2px !important;
      background-color: #e4e7ec !important;
    }
  }
  .ant-steps-item-wait {
    display: none !important;
  }
}

.table-dataleak-pricing {
  .ant-table-row {
    &:nth-child(2n + 1) {
      .ant-table-cell {
        @apply bg-grey-background;
      }
    }
  }
  .ant-table-cell {
    @apply text-sm w-1/5 md:w-auto;
    min-height: 72px;
    padding: 24px 16px !important;
    border-bottom: 0 !important;
  }
}

.table-trust-center-pricing {
  .ant-table-row {
    .ant-table-cell {
      &:nth-child(2),
      &:nth-child(3) {
        color: #475467;
      }
    }
    &:nth-child(2n + 1) {
      .ant-table-cell {
        @apply bg-grey-background;
      }
    }
  }
  .ant-table-cell {
    @apply text-sm w-1/3 md:w-auto;
    min-height: 72px;
    padding: 24px 24px !important;
    border-bottom: 0 !important;
  }
}
