@import "antd/dist/reset.css";

@import "../custom/button";
@import "../custom/input";
@import "../custom/select";
@import "../custom/form";
@import "../custom/collapse";
@import "../custom/table";
@import "../custom/checkbox";

.ant-input:placeholder-shown {
  font-family: Inter, sans-serif !important;
}

textarea.ant-input {
  font-family: Inter, sans-serif !important;
}

.ant-form-item .ant-form-item-label {
  font-family: Inter, sans-serif !important;
  label {
    font-family: Inter, sans-serif !important;
  }
}

.endpoint-features,
.vulnscan-features,
.trust-center-reason {
  .ant-collapse-item {
    padding-left: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
  }
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    padding-top: 24px;
    padding-right: 20px;
  }
  .ant-collapse {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      bottom: 0;
      transition: all 0.3s ease-in-out;
      background-color: #f2f4f7;
    }
  }
  .ant-collapse-item {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      bottom: 100%;
      transition: all 0.3s ease-in-out;
    }
    .ant-collapse-header {
      align-items: center;
    }
    .header-icon {
      color: #d0d5dd;
      background-color: #f2f4f7;
      box-shadow: 0px 0px 0px 6px #ffffff;
      transition: all 0.3s ease;
    }
  }
  .ant-collapse-item-active {
    .ant-collapse-header {
      pointer-events: none;
    }
    &::before {
      bottom: 0;
      background-color: #1f1fe8;
    }
    .header-icon {
      color: #ffffff;
      background-color: #1f1fe8;
      box-shadow: 0px 0px 0px 6px #e2e2ff;
    }
  }
}

.trust-center-reason {
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background-color: #101828;
    color: #ffffff;
  }
  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 16px;
  }
  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 16px;
  }
  .ant-table {
    .ant-table-container {
      border-end-start-radius: 16px;
      border-end-end-radius: 16px;
    }
  }
  .ant-table-wrapper .ant-table-tbody > tr {
    &:last-child > td {
      &:first-child {
        border-end-start-radius: 16px;
      }
    }
    &:last-child {
      border-end-start-radius: 16px;
      td {
        &:last-child {
          border-end-end-radius: 16px;
        }
      }
    }
  }
}
