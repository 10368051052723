.ant-btn {
  border-radius: 8px !important;
  @apply font-semibold text-[16px] leading-6 h-11 px-4;
  &.ant-btn-sm {
    @apply font-semibold text-[14px] leading-5 h-10 px-[18px];
  }
  &.ant-btn-lg {
    @apply font-semibold text-[20px] leading-[30px] h-14 px-[22px];
  }
  &.ant-btn-text-primary {
    @apply text-primary-500 hover:border-[#1818B5] hover:text-[#1818B5];
  }
  &.ant-btn-primary {
    &:not(.ant-btn-background-ghost) {
      @apply bg-primary-500;
    }
  }
}
