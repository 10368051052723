@font-face {
  font-family: "Inter";
  src: url("../../../public/fonts/Inter-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: "Inter";
  src: url("../../../public/fonts/Inter-ExtraLight.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Inter";
  src: url("../../../public/fonts/Inter-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: url("../../../public/fonts/Inter-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Inter";
  src: url("../../../public/fonts/Inter-Regular.ttf");
}
@font-face {
  font-family: "Inter";
  src: url("../../../public/fonts/Inter-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Inter";
  src: url("../../../public/fonts/Inter-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Inter";
  src: url("../../../public/fonts/Inter-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Inter";
  src: url("../../../public/fonts/Inter-ExtraBold.ttf");
  font-weight: 800;
}
@font-face {
  font-family: "Inter";
  src: url("../../../public/fonts/Inter-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Cystack";
  src: url("../../../public/fonts/Cystack-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Cystack";
  src: url("../../../public/fonts/Cystack-Regular.ttf");
}
@font-face {
  font-family: "Cystack";
  src: url("../../../public/fonts/Cystack-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Cystack";
  src: url("../../../public/fonts/Cystack-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Cystack";
  src: url("../../../public/fonts/Cystack-Bold.ttf");
  font-weight: 700;
}
