.tcw {
  .ant-menu-inline {
    border-inline-end: none !important;
    .ant-menu-submenu-inline {
      .ant-menu-submenu-title {
        .ant-menu-title-content {
          @apply mr-4;
        }
        margin-inline: 0px;
        margin-block: 0px;
        width: 100%;
        background-color: #f7f9fb;
        @apply py-[10px] lg:px-4 px-6;
        @media (min-width: 1024px) {
          padding-left: 24px !important;
        }
        padding-left: 16px !important;
      }
      .ant-menu-sub {
        background: none !important;
        .ant-menu-item {
          width: 100%;
          margin-top: 8px;
          margin-bottom: 0px;
          @apply py-[10px] lg:px-4 px-6;
          @media (min-width: 1024px) {
            padding-left: 24px !important;
          }
          padding-left: 16px !important;
          &-selected {
            background-color: #f7f9fb;
          }
        }
      }
    }
  }
}