.ant-form-item .ant-form-item-label {
  @apply block;
  label {
    align-items: start;
    padding-top: 12px;
    height: auto !important;
    &::before {
      padding-top: 6px;
    }
  }
}

.ant-form-item .ant-form-item-label {
  padding-bottom: 6px;
}

.ant-form-item .ant-form-item-label > label {
  @apply h-full text-black after:hidden text-body3;
}

.ant-form-item .ant-form-item-label > label {
  position: relative;
  padding-top: 0;
  line-height: 1;
  &::before {
    position: absolute;
    top: 0;
    right: -14px;
    margin-right: 0;
    font-weight: 500;
    color: #d92d20;
    padding-top: 0;
  }
}

.ant-form-item-explain-error {
  position: absolute;
}
