.common-section-partner {
  &__list {
    padding: 0;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 350px;
    }
    &::before {
      z-index: 1;
      left: 0;
      background: linear-gradient(90deg, #f0f0f0 0%, rgba(240, 240, 240, 0) 100%);
    }
    &::after {
      z-index: 1;
      right: 0;
      background: linear-gradient(270deg, #f0f0f0 0%, rgba(240, 240, 240, 0) 100%);
    }
  }

  @keyframes scrollRtl {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(220px * 10 * -1));
    }
  }
  @keyframes scrollLtr {
    0% {
      transform: translateX(calc(220px * 10 * -1));
    }
    100% {
      transform: translateX(0);
    }
  }

  .carousel__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    &:hover {
      .carousel__slide {
        animation-play-state: paused;
      }
    }
  }

  .carousel {
    padding-top: 100px;
    overflow: hidden;
    width: 100%;
  }
  .right-to-left {
    .carousel__slide {
      animation: scrollRtl 30s linear 0s infinite;
    }
  }
  .left-to-right {
    .carousel__slide {
      animation: scrollLtr 30s linear 0s infinite;
    }
  }
  .carousel__slide {
    display: flex;
    flex-direction: column;

    flex: 0 0 auto;
    width: 220px;
    height: 110px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel__image {
    margin: 15px 20px;
  }

  .carousel__slide {
    position: relative;
  }

  .carousel {
    counter-reset: slideNo;
  }
}
.swiper-pagination {
  display: flex;
  margin-top: 64px;
  width: fit-content !important;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.swiper-pagination-bullet {
  border-radius: 999px;
  height: 8px;
  width: 42px;
  display: inline-block;
  background-color: #e4e7ec;
  transition: all 0.2s ease;
  &.swiper-pagination-bullet-active {
    background-color: #22f;
    width: 10px;
  }
}

.key-objective-red-teaming {
  .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
    cursor: pointer;
    &.swiper-pagination-bullet-active {
      width: 10px;
    }
  }
}
