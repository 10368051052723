.ant-table-cell {
  @apply text-body3
}
.table-products {
  .ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td {
    color: #000000;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.2px;
    background: #F4F5F7;
  }
  
  .ant-table-wrapper .ant-table-tbody >tr >td {
    color: #000000;
    background: #F4F5F7;
    font-size: 16px;
    line-height: 28px;
    &.td-bg-grey {
      background: #F4F5F7 !important;
      font-weight: 600;
      text-align: center;
      padding: 10px 12px;
    }
    &.td-bg-white {
      background: #fff;
      font-weight: 600;
    }
  }
  
  .ant-table-wrapper .ant-table-tbody>tr>td, .ant-table-wrapper tfoot>tr>th, .ant-table-wrapper tfoot>tr>td {
    padding: 10px 18px;
  }
  
  .ant-table-wrapper .ant-table-thead>tr>th {
    padding: 22px 18px;
  }
  
  .ant-table-wrapper .ant-table-tbody >tr {
    &:nth-child(2n + 1) > td {
      background: #fff;
    }
  }
}

.table-projects {
  .ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td {
    background: transparent;
    padding-bottom: 50px;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
    background: #fff;
    font-weight: 400;
    &::before {
      display: none;
    }
  }
  .ant-table-wrapper .ant-table-thead>tr>th, .ant-table-wrapper .ant-table-tbody>tr>th, .ant-table-wrapper .ant-table-tbody>tr>td, .ant-table-wrapper tfoot>tr>th, .ant-table-wrapper tfoot>tr>td {
    padding: 24px 16px;
  }
}