.tcw {
  .ant-avatar-group {
    &-popover {
      .ant-popover-content {
        .ant-popover-inner {
          .ant-popover-inner-content {
            @apply w-[200px] overflow-x-scroll whitespace-nowrap;
            scrollbar-width: thin;
            > div {
              @apply inline-block [&:not(:first-child)]:-ml-2;
            }
          }
        }
      }
    }
  }
}
