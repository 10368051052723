.security-operations-solution-banner {
  background-image: url("../../../public/images/security-operations/bg-banner.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.security-operations-case-study {
  .swiper-slide {
    height: auto;
  }
}
