.trust-center-standard {
  background-image: url("../../../public/images/trust-center/BackgroundStandard.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  .common-section-partner {
    &__list {
      &::before {
        background: linear-gradient(90deg, #f9fafb 0%, rgba(249, 250, 251, 0) 100%);
      }
      &::after {
        background: linear-gradient(270deg, #f9fafb 0%, rgba(250, 250, 250, 0) 100%);
      }
      .carousel__slide {
        height: 144px;
      }
    }
  }
}

.trust-center-how {
  .ant-table-row {
    .ant-table-cell {
      vertical-align: top;
      padding: 20px 24px !important;
    }
    &:nth-child(2n + 1) {
      .ant-table-cell {
        background-color: #f9fafb !important;
      }
    }
  }
}
