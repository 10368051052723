@import "./base/_reset";

// sass-utils
@import "./sass-utils/_fonts";
@import "./sass-utils/_common";

// vendors
@import "./vendors/_tailwind";
@import "./vendors/_antd";

@import "./page/ransomware.scss";
@import "./page/homepage.scss";
@import "./page/endpoint.scss";
@import "./page/vulnerability.scss";
@import "./page/platform.scss";
@import "./page/blog.scss";
@import "./page/infrastructure.scss";
@import "./page/blockchain-security.scss";
@import "./page/security-audit.scss";
@import "./page/data-security.scss";
@import "./page/security-operations.scss";
@import "./page/data-leak.scss";
@import "./page/sme.scss";
@import "./page/thank-you.scss";
@import "./page/trust-center.scss";
@import "./page/red-teaming.scss";

// base

@media screen and (max-width: 768px) {
  .section-introduce {
    .ant-carousel {
      width: 100%;
    }
  }
}

html {
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth !important;
  font-family: "Inter", sans-serif !important;
}

.font-cystack {
  font-family: "Cystack", sans-serif !important;
}

.font-inter {
  font-family: "Inter", sans-serif !important;
}

.animate {
  animation: animate 0.8s;
}

.animateRTL {
  animation: animateRTL 0.8s;
}
.animateLTR {
  animation: animateLTR 0.8s;
}

@keyframes animate {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animateLTR {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes animateRTL {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

section {
  .section-main {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
  .ransomware-container {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    @apply px-[120px] 2xl:px-20 xl:px-5 md:px-3;
  }
  .new-container {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    @apply px-[80px] 2xl:px-10 xl:px-5 md:px-3;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: "Cystack", sans-serif;
    }
  }
  .container-fuild {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    font-family: "Inter";
    @apply px-[80px] 2xl:px-10 xl:px-5 md:px-3;
  }
}

.custom-dots-carousel {
  li {
    background-color: #dadee3;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50%;
    &.slick-active {
      background-color: #121212;
      button {
        background-color: #121212 !important;
      }
    }
    button {
      height: 100% !important;
      border-radius: 50% !important;
    }
  }
}

.products-common-banner-background {
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.link-more {
  display: none;
}

.posts-detail {
  &__content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 24px;
    }
    a {
      @apply text-primary-500;
      word-break: break-all;
    }
    div,
    p {
      margin-bottom: 24px !important;
      @apply text-body1;
    }
    ul, ol {
      margin-left: 16px;
      li {
        @apply text-body1;
      }
    }
    figure {
      max-width: 100% !important;
      width: 100% !important;
      display: block !important;
    }
    img {
      max-width: 100%;
      height: auto;
      object-fit: contain;
      display: block;
      margin: 24px auto;
    }
    code {
      word-break: break-all;
    }
    .wp-block-embed__wrapper {
      word-break: break-all;
    }
    .notion-page {
      max-width: 100%;
      width: 100%;
    }
  }
}
.press-modal-video {
  .ant-modal-content {
    width: min-content;
  }
}

.press-bg-gradient {
  background: linear-gradient(180deg, #22a2ff 0%, #2262ff 69.43%, #2222ff 138.86%);
}

.form-request-banner {
  .ant-form-item-label {
    display: none;
  }
}

.rnr-paragraph,
.rnr-bulleted_list_item {
  margin-bottom: 24px !important;
  font-size: 20px;
  line-height: 36px;
}

.rnr-heading_3 {
  margin-bottom: 24px;
  font-size: 34px;
  line-height: 46px;
  font-weight: 700;
}

.rnr-heading_3:hover {
  margin-bottom: 24px;
  font-size: 34px;
  line-height: 46px;
  font-weight: 700;
  color: black;
}

.rnr-image {
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 24px;
}

.case-container {
  a {
    transition: none !important;
  }
}

.ant-progress-text {
  font-size: 24px;
  line-height: 40px;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-gradient-1 {
  background: linear-gradient(180deg, #22a2ff 20.71%, #2262ff 72.14%, #2222ff 123.57%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.text-gradient-purple-neon {
  background: linear-gradient(90deg, #d842f0 1.15%, #cc5ee5 36.42%, #9a77ff 68.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.text-gradient-green-blue {
  background: linear-gradient(67.94deg, #2afadf 15.01%, #3ac3ee 51.21%, #4c83ff 85.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.text-gradient-yellow-red {
  background: linear-gradient(90deg, #ffa500 0%, #ff5600 50.52%, #ff0000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-gradient-purple-neon {
  background: linear-gradient(90deg, #ff45cb 0%, #cc5ee5 44.38%, #9a77ff 88.77%);
}

.bg-gradient-green-blue {
  background: linear-gradient(90deg, #79f1c0 0.49%, #66ccdb 47.13%, #4c9aff 100%);
}

.bg-gradient-yellow-red {
  background: linear-gradient(90deg, #ffa53a 0%, #ff7d53 50%, #ff3e3e 100%);
}

.bg-gradient-home-contact {
  background: linear-gradient(
    90deg,
    rgba(34, 162, 255, 0.5) 1.04%,
    rgba(34, 98, 255, 0.5) 65.94%,
    rgba(34, 34, 255, 0.5) 126.91%
  );
}

.bg-gradient-green-blue {
  background: linear-gradient(90deg, #79f1c0 0.49%, #66ccdb 47.13%, #4c9aff 100%);
}

.bg-gradient-yellow-red {
  background: linear-gradient(90deg, #ffa53a 0%, #ff7d53 50%, #ff3e3e 100%);
}

.bg-green-blue {
  background: linear-gradient(67.94deg, #2afadf 15.01%, #3ac3ee 51.21%, #4c83ff 85.93%);
}

.library-download-popup {
  .ant-modal-content {
    padding: 0;
    border-radius: 4px;
    overflow: hidden;
  }
}

.cystack-header {
  .nav {
    &__menu {
      display: flex;
      &--item {
        .submenu {
          opacity: 0;
          visibility: hidden;
          z-index: 10;
          background: #fff;
          top: calc(100% + 16px);
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          transition: all 0.2s ease;
        }
      }
    }
  }

  #nav-icon3 {
    width: 24px;
    height: 18px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #121212;
      border-radius: 2px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
  }
  #nav-icon3 span:nth-child(1) {
    top: 0px;
  }

  #nav-icon3 span:nth-child(2),
  #nav-icon3 span:nth-child(3) {
    top: 8px;
  }

  #nav-icon3 span:nth-child(4) {
    top: 16px;
  }

  #nav-icon3.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  #nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #nav-icon3.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  .main-menu-mobile {
    display: none;
  }

  .submenu-mobile {
    display: none;
  }
}

@media screen and (max-width: 1280px) {
  .cystack-header {
    .nav {
      &__menu {
        display: none;
      }
    }
    .main-menu-mobile {
      display: block;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      &.is-show {
        visibility: visible;
        opacity: 1;
        right: 0 !important;
        animation: fadeIn ease 0.3s;
        -webkit-animation: fadeIn ease 0.3s;
        -moz-animation: fadeIn ease 0.3s;
        -o-animation: fadeIn ease 0.3s;
        -ms-animation: fadeIn ease 0.3s;
      }
    }
    .submenu-mobile {
      display: block;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      &.is-show {
        opacity: 1;
        visibility: visible;
        right: 0 !important;
        animation: fadeIn ease 0.3s;
        -webkit-animation: fadeIn ease 0.3s;
        -moz-animation: fadeIn ease 0.3s;
        -o-animation: fadeIn ease 0.3s;
        -ms-animation: fadeIn ease 0.3s;
      }
    }
  }
}

.ant-carousel .slick-dots {
  z-index: 5;
}

.animation-fade-in {
  animation: fadeIn ease 0.4s;
  -webkit-animation: fadeIn ease 0.4s;
  -moz-animation: fadeIn ease 0.4s;
  -o-animation: fadeIn ease 0.4s;
  -ms-animation: fadeIn ease 0.4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1 !important;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1 !important;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1 !important;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// CSS text why-cystack
.vulerability-scanning {
  background: linear-gradient(90deg, #ffa500 0%, #ff5600 50.52%, #ff0000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.attack {
  background: linear-gradient(90deg, #d842f0 0%, #9c88f7 49.83%, #57d7ff 99.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.continuous {
  background: linear-gradient(90deg, #ff0844 1.15%, #ff5c6e 49.89%, #ffb199 99.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.threat {
  background: linear-gradient(67.94deg, #2222ff 15.01%, #2222ff 51.21%, #2222ff 85.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.coccoc-alo-ph-circle {
  width: 120px;
  height: 120px;
  top: 0;
  left: 0;
  position: absolute;
  background-color: transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid rgba(30, 30, 30, 0.4);
  opacity: 0.1;
  -webkit-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.coccoc-alo-phone {
  background-color: transparent;
  width: 120px;
  height: 120px;
  cursor: pointer;
  z-index: 200000 !important;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -webkit-transition: visibility 0.5s;
  -moz-transition: visibility 0.5s;
  -o-transition: visibility 0.5s;
  transition: visibility 0.5s;
  right: 150px;
  top: 30px;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle-fill {
  background-color: #2262ff;
  opacity: 0.5 !important;
}

.coccoc-alo-ph-circle-fill {
  width: 80px;
  height: 80px;
  top: 20px;
  left: 20px;
  position: absolute;
  background-color: #000;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.1;
  -webkit-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.coccoc-alo-ph-img-circle {
  width: 40px;
  height: 40px;
  top: 40px;
  left: 40px;
  position: absolute;
  background: rgba(30, 30, 30, 0.1) url(/icons/phone.png) no-repeat center center;
  background-size: 25px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  -webkit-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-img-circle {
  background-color: #2222ff;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle {
  border-color: #2222ff;
  opacity: 0.5;
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-circle,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-circle {
  border-color: #75eb50;
  opacity: 0.5;
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-circle-fill,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-circle-fill {
  background-color: rgba(117, 235, 80, 0.5);
  opacity: 0.75 !important;
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-img-circle,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-img-circle {
  background-color: #75eb50;
}

@-moz-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-webkit-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-o-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-moz-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-o-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-moz-keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@-webkit-keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@-o-keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

// New CyStack v3
.common-new-contact {
  .content {
    background-image: url("../../public/images/endpoint/bg-contact.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.form-join-community {
  .ant-modal {
    width: fit-content !important;
    .ant-modal-content {
      .ant-modal-close {
        top: 0;
        right: 0;
      }
      padding: 0;
      background-color: transparent;
      box-shadow: none;
      border: none;
      .ant-modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.5);
}

.common-icon {
  border: 1px rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(8px);
  @apply flex items-center justify-center rounded-[10px] w-full h-full;
}

.common-subheader {
  transition: all 0.3s ease;
  background-color: #fff;
  z-index: 10;
  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}

.ant-segmented {
  max-width: 100%;
  padding: 6px;
  background-color: #f9fafb;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  @apply sm:overflow-x-auto;
  .ant-segmented-group {
    max-width: 100%;
  }
  .ant-segmented-item-label {
    padding: 10px 12px;
    @apply text-md md:text-sm font-semibold;
    color: #344054;
  }
  .ant-segmented-item-selected {
    box-shadow:
      0px 1px 3px 0px rgba(16, 24, 40, 0.1),
      0px 1px 2px 0px rgba(16, 24, 40, 0.06) !important;
    .ant-segmented-item-label {
      color: #344054;
    }
  }
}

.main-blog-content {
  .rt-reading-time {
    display: none !important;
  }
  @apply text-lg;
  h2 {
    @apply text-display-sm font-semibold my-3;
    strong {
      @apply font-semibold;
    }
  }
  h3 {
    @apply text-display-xs font-semibold my-2;
    strong {
      @apply font-semibold;
    }
  }
  ul,
  ol {
    @apply pl-5;
  }
  div,
  p,
  li {
    @apply text-md leading-7 mb-6 text-dark-700;
  }
  a {
    @apply text-primary-500 hover:underline;
  }
  figure {
    max-width: 100% !important;
    width: 100% !important;
    display: block !important;
  }
  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
    margin: 24px auto;
  }
  code {
    word-break: break-all;
  }
}
.contact-sales {
  .background-overview {
    overflow: hidden;
    position: relative;
    background-image: url("../../public/images/contact-sales/LightAccent.svg");
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.nd-13-banner {
  .overlay {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20.61%, rgba(0, 0, 0, 0.9) 100%),
      linear-gradient(0deg, rgba(6, 17, 66, 0.3) 0%, rgba(6, 17, 66, 0.3) 100%);
  }
}

.nd-13-service-item {
  background: linear-gradient(297deg, rgba(242, 242, 255, 0.3) 21.52%, rgba(255, 255, 255, 0.5) 100%);
  backdrop-filter: blur(12px);
}

.security-policy-partner {
  &.common-section-partner {
    .common-section-partner {
      &__list {
        &::before {
          width: 170px;
          background: linear-gradient(90deg, #F9FAFB 0%, rgba(249, 250, 251, 0.00) 100%);
        }
        &::after {
          width: 170px;
          background: linear-gradient(270deg, #F9FAFB 0%, rgba(249, 250, 251, 0.00) 100%);
        }
      }
    }
    .carousel__wrapper {
      gap: 24px;
      height: 80px !important;
    }
    .carousel__slide {
      width: 160px !important;
    }
  }
}

.security-policy-development-integrate {
  .ant-table {
    border: 1px solid #EAECF0;
    border-radius: 16px;
    thead {
      th {
        background-color: #101828;
        color: #ffffff;
        &::before {
          background-color: #344054 !important;
        }
      }
    }
    tr {
      &:nth-child(2n) {
        td {
          background-color: #f9fafb;
        }
      }
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}

.protect-data-chart {
  .ant-segmented-item {
    .ant-segmented-item-label {
      color: #667085;
    }
    &.ant-segmented-item-selected {
      .ant-segmented-item-label {
        color: #344054;
      }
    }
  }
  .ant-segmented.ant-segmented-lg .ant-segmented-item-label {
    min-height: 44px;
    line-height: 44px;
    text-align: left;
  }
}

.protect-data-banner {
  background-image: url("../../public/images/protect-data/BackgroundBanner.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.protect-data-patner {
  &.common-section-partner {
    .common-section-partner {
      &__list {
        &::before {
          width: 380px;
          background: linear-gradient(90deg, #F9FAFB 0%, rgba(249, 250, 251, 0.00) 100%);
        }
        &::after {
          width: 380px;
          background: linear-gradient(270deg, #F9FAFB 0%, rgba(249, 250, 251, 0.00) 100%);
        }
      }
    }
    .right-to-left {
      .carousel__slide {
        animation: scrollRightToLeft 60s linear 0s infinite;
      }
    }
    .carousel__wrapper {
      gap: 24px;
    }
    .carousel__slide {
      width: 118px !important;
    }
  }
}
.ads-award {
  &.common-section-partner {
    .common-section-partner {
      &__list {
        &::before {
          width: 112px;
          background: linear-gradient(90deg, #F9FAFB 0%, rgba(249, 250, 251, 0.00) 100%);
        }
        &::after {
          width: 112px;
          background: linear-gradient(270deg, #F9FAFB 0%, rgba(249, 250, 251, 0.00) 100%);
        }
      }
    }
    .right-to-left {
      .carousel__slide {
        animation: scrollRightToLeftAward 40s linear 0s infinite;
      }
    }
    .carousel__wrapper {
      gap: 24px;
    }
    .carousel__slide {
      width: 350px !important;
    }
  }
}
@keyframes scrollRightToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(118px * 26 * -1));
  }
}
@keyframes scrollRightToLeftAward {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(350px * 7 * -1));
  }
}

.protect-data-pricing-table {
  td {
    width: 20% !important;
  }
  .active-top {
    border: 0 !important;
    &::before {
      content: '';
      position: absolute;
      left: 0px;
      right: 0px;
      border-bottom: 0 !important;
      top: 0;
      border-radius: 12px;
      bottom: -1px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid #4E4EFF;
    }
  }
  .active-bottom {
    border: 0 !important;
    &::before {
      content: '';
      position: absolute;
      left: 0px;
      right: 0px;
      border-top: 0 !important;
      top: 0;
      border-radius: 12px;
      bottom: -1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border: 1px solid #4E4EFF;
    }
  }
  .active {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0px;
      right: 0px;
      border-top: 0 !important;
      border-bottom: 0 !important;
      top: 0;
      bottom: -1px;
      border: 1px solid #4E4EFF;
    }
  }
}

.table-data-protection {
  table {
    td {
      width: 20% !important;
    }
  }
}

.table-security-audit-solution {
  .ant-table-row {
    .ant-table-cell {

      &:nth-child(2),
      &:nth-child(3) {
        color: #475467;
      }
    }

    &:nth-child(2n + 1) {
      .ant-table-cell {
        @apply bg-grey-background;
      }
    }
  }

  .ant-table-cell {
    vertical-align: top;
    @apply text-sm md:w-auto;
    width: calc(100%/6) !important;
    min-height: 72px;
    padding: 24px 24px !important;
    border-bottom: 0 !important;
  }
}

.modal-contact-sale-ads {
  .select-multiple {
    .ant-select-selector {
      padding: 10px;
    }
    .ant-select-selection-item {
      background-color: #fff !important;
      border-radius: 6px;
      border: 1px solid #D0D5DD !important;
      min-height: 28px;
      padding-top: 4px;
      padding-bottom: 4px;
      .ant-select-selection-item-content {
        font-size: 14px !important;
        line-height: 20px !important;
        font-weight: 500;
        color: #344054;
      }
      .anticon-close {
        color: #98A2B3;
        &:hover {
          color: red;
        }
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
    
  }
}

.select-product-multiple-dropdown  {
  .ant-select-item-option {
    margin-bottom: 2px;
    @apply text-md py-2 px-[14px] text-tertiary-600;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .ant-select-item-option-selected {
    background-color: #F9FAFB !important;
    .anticon-check {
      svg {
        width: 20px;
        height: 20px;
        color: #2222ff;
      }
    }
  }
}

.ads-blockchain-security-banner {
  background-image: url("../../public/images/ads-blockchain-security/BackgroundBanner.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}
.ads-blockchain-security-why-cystack {
  background-image: url("../../public/images/ads-blockchain-security/BackgroundWhyCystack.svg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}

.custom-icon-covered {
  background-image: url("../../public/images/ads-blockchain-security/background-solution-covered.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.custom-icon-protection {
  background-image: url("../../public/images/ads-blockchain-security/background-solution-protection.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@keyframes scrollRightToLeftBlockchainSecurity {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(160px * 10 * -1));
  }
}
.ads-blockchain-security-partner {
  &.common-section-partner {
    .common-section-partner {
      &__list {
        &::before {
          width: 380px;
          background: linear-gradient(90deg, #0C111D 0%, rgba(12, 17, 29, 0.00) 100%);
        }
        &::after {
          width: 380px;
          background: linear-gradient(270deg, #0C111D 0%, rgba(12, 17, 29, 0.00) 100%);
        }
      }
    }
    .right-to-left {
      .carousel__slide {
        animation: scrollRightToLeftBlockchainSecurity 30s linear 0s infinite;
      }
    }
    .carousel__wrapper {
      gap: 24px;
    }
    .carousel__slide {
      width: 160px !important;
    }
  }
}

.custom-icon-process-work {
  background-image: url("../../public/images/ads-blockchain-security/background-solution-protection.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.ads-blockchain-security-progress-container {
  background-image: url("../../public/images/ads-blockchain-security/BackgroundProgressWork.svg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}
.ads-smart-contract-audit-progress-container {
  background-image: url("../../public/images/ads-blockchain-security/BackgroundProgressWork.svg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}

.ads-smart-contract-audit-critical {
  .ant-collapse-header {
    padding-left: 12px !important;
    padding-right: 24px !important;
  }
  .ant-collapse-content-box {
    padding-left: 12px !important;
    padding-right: 24px !important;
    padding-top: 0 !important
  }
}

.ads-smart-contract-audit-inside-our {
  .ant-steps {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ant-steps-item {
      &:last-child {
        display: none;
      }
    }
  }
  .step-item {
    background-image: url("../../public/images/ads-smart-contract-audit/BackgroundStep.svg");
    background-position: right;
    background-size: 55% 100%;
    background-repeat: no-repeat;
    @apply md:bg-none;
  }
  .ant-segmented-group {
    gap: 8px;
  }
  .ant-segmented-item-label {
    white-space: normal !important;
    font-size: 16px;
    line-height: 24px !important;
    max-width: 225px !important;
    padding: 8px 20px !important;
  }
}

.ads-penetration-testing-inside-our {
  .ant-steps-item-description {
    max-width: 100% !important;
  }
  .ant-segmented-item {
    flex: 1;
  }
}

.rfm-child {
  height: 100%;
}

.ads-penetration-testing-smarter {
  .ant-collapse-item {
    .ant-collapse-header {
      @apply py-6 px-3 items-center border border-solid border-[#D0D5DD] border-l-0 border-r-0 border-t-0 rounded-lg;
      .ant-collapse-header-text {
        @apply text-[24px] leading-8 lg:text-xl text-[#98A2B3]
      }
    }
    &.ant-collapse-item-active {
      box-shadow: 0px 1px 6.1px 0px rgba(16, 24, 40, 0.10), 
            0px 1px 2px 0px rgba(16, 24, 40, 0.06);
      border-radius: 6px;
      .ant-collapse-header {
        @apply py-12 px-12 items-center border-none lg:py-6 lg:px-6;
        .ant-collapse-expand-icon {
          display: none;
        }
        .ant-collapse-header-text {
          @apply text-[32px] leading-[48px] lg:text-display-xs text-dark-700 font-semibold font-inter;
        }
      }
      .ant-collapse-content-box {
        @apply px-12 py-0 pb-12 lg:px-6 lg:pb-6;
      }
    }
  }
}

